import React from "react";
import { Button, Dropdown, Avatar, Menu } from "antd";
import { UserOutlined } from '@ant-design/icons';

import "./AppNavbar.css";
import { useAuth } from "../../Auth/AuthProvider";

export interface ProfileMenuProps {
}

export default function ProfileMenu(props: ProfileMenuProps) {
  const { isAuthenticated, loading, user, logout } = useAuth();

  if (loading) return <Avatar shape="square" icon="user" />;
  if (!isAuthenticated) return <Avatar shape="square" icon="user" />;
  if (!user) return <Avatar shape="square" icon="user" />;

  const { email, picture } = user;

  const onLogout = () => {
    logout();
  };

  const menu = (
    <div style={{ backgroundColor: "white" }}>
      <Menu>
        <Menu.Item>
          <span>{email}</span>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={onLogout}>
            <span>Logg ut</span>
          </a>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      {picture ? <Avatar shape="square" src={picture} /> : <Avatar shape="square" icon={<UserOutlined />} /> }
    </Dropdown>
  );
}
