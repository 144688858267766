import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthProvider";

import 'antd/dist/antd.css';

import App from './App';
import history from "./history"; 
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <AuthProvider
    domain="roadworks.eu.auth0.com"
    client_id="TavECpcAj7RaldXTzixza4ffuqB1VoXj"
    leeway={300}
    onRedirectCallback={onRedirectCallback}
    redirect_uri={window.location.origin}
  >
    <Router history={history}>
      <App />
    </Router>
  </AuthProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
