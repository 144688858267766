import React from "react";
import ProfileMenu from './ProfileMenu';

import "./AppNavbar.css";

export interface AppNavbarProps{
}

export default function AppNavbar(props: AppNavbarProps) {

  return (
    <div className="app-navbar">
      <div className="app-navbar-title-container">
        <div className="app-navbar-title">
          <img src="/assets/images/logo.svg" width="100px" />
          <p>{process.env.REACT_APP_NAME}</p>
        </div>
      </div>
      <div className="app-navbar-profile-container">
        <div style={{ marginRight: "15px", marginTop: "4px" }}>
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
}
