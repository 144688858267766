import React from "react";
import { Card, Tooltip } from "antd";
import { ReadOutlined } from "@ant-design/icons";

export interface CardsProps{
}

export default function Cards(source:any) {
  const data = source.cardSource;
  const { Meta } = Card;

  function handleClick() {
    if(!data.links[0].url) 
    {
      window.open(data.primaryLink);
    } return null
  };
  
  function handleClicks() {
    if (data.links[0].url){
      window.open(data.primaryLink);
    }else return null;
  };

  const linksUrl = () => {
    window.open(data.links[0].url);
  };

  const action = data.links.map((u:any) => (
    <Tooltip placement="right" title={u.description}>
      <ReadOutlined key="setting" onClick={linksUrl}/>
    </Tooltip>
  ));

  return (
    <Tooltip placement="top" title={data.tooltip}>
      <Card
        onClick={handleClick}
        className="cards"
        style={{ borderWidth: "1px" }}
        hoverable={true}
        bordered={true}
        cover={
          <div
            onClick={handleClicks}
            style={{ paddingTop: "24px" }}
          >
            <img
              alt=""
              src={data.iconUrl}
              style={{
                maxHeight: 35,
                maxWidth: 60,
                minHeight: 35,
                minWidth: 60,
              }}
            />
          </div>
        }
        actions={data.links[0].url ? action : ""}
      >
        <div
          onClick={handleClicks}
          style={{ height: "100%", padding: "24px 15px 0 15px" }}
        >
          <Meta description={data.title} />
        </div>
      </Card>
    </Tooltip>
  );
};
