import React, { useEffect } from "react";
import { Switch } from "react-router-dom";

import "./App.css";

import AppNavbar from "./Components/Navbar/AppNavbar";
import PrivateRoute from "./Auth/PrivateRoute";
import Dashboard from "./Components/Dashboard/Dashboard";

import { Route, useLocation } from "react-router";
import { QueryParamProvider } from "use-query-params";
import ReactGa from "react-ga";

export interface AppProps {}

export default function App(props: AppProps) {
  function usePageViews() {
    const location = useLocation();
    if (document.location.hostname !== "localhost") {
      ReactGa.initialize("UA-158199604-3");
      ReactGa.set({ page: location.pathname });
      ReactGa.pageview(location.pathname + location.search);
    }
  }

  usePageViews();

  return (
    <div>
      <AppNavbar />
      <Switch>
        <QueryParamProvider ReactRouterRoute={Route}>
          <PrivateRoute path="/" component={Dashboard} />
        </QueryParamProvider>
      </Switch>
    </div>
  );
}
