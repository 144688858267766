import React from "react";
import { Tabs, Layout, Col, Row } from "antd";

import { DashboardOutlined, AuditOutlined } from "@ant-design/icons";

import dashboardLinks from "../../Data/links.json";
import suppliers from "../../Data/suppliers.json";
import "./Dashboard.css";
import Cards from "./Cards";
import { StringParam, useQueryParam } from "use-query-params";

export interface DashboardProps {}

export default function Dashboard(props: DashboardProps): JSX.Element {
  const linksDashboard = createSection(dashboardLinks);
  const linksSuppliers = createSection(suppliers);

  const [chosenTab, setChosenTab] = useQueryParam("tab", StringParam);

  function tabChosen(tabId: string) {
    setChosenTab(tabId);
  }

  return (
    <Layout>
      <Tabs
        defaultActiveKey="dashboard"
        animated={false}
        onTabClick={tabChosen}
      >
        <Tabs.TabPane
          tab={
            <span>
              <DashboardOutlined />
              Dashboard
            </span>
          }
          key="dashboard"
        >
          {linksDashboard}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <AuditOutlined />
              Leverandører
            </span>
          }
          key="leverandor"
        >
          {linksSuppliers}
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );

  function createSection(data: any) {
    return Object.keys(data).map((e) => {
      const section = data[e];
      const cols = section.map((f: any) => (
        <Col xs={8} sm={8} md={6} lg={4} xl={3} xxl={2}>
          <Cards cardSource={f} />
        </Col>
      ));
      for (let i = 0; i < section.length; i++) {
        cols.push();
      }

      return (
        <div>
          <h1>{e}</h1>
          <Row justify="start" gutter={[16, 16]}>
            {cols}
          </Row>
        </div>
      );
    });
  }
}
